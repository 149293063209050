import {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

export interface SelectBoxOption {
  value: string
  label: string
}
export const SelectBox: FC<{options: SelectBoxOption[], value: string|null, onChange: (val: string|null) => void}> = props => {
  const optionStyle = 'block w-full py-2 cursor-pointer px-3 text-left leading-tight'
  const selectedOptionStyle = 'block w-full py-2 cursor-pointer bg-brand-600 text-white px-3 text-left font-medium leading-tight'
  const [search, setSearch] = useState('')
  const filteredOptions = props.options.filter(option => option.value === props.value || option.label.toLowerCase().includes(search.toLowerCase()))
  return <div className={"border border-slate-300 rounded h-72 flex flex-col items-stretch"}>
    <div className={"flex items-center"}>
      <input type="text" className={"flex-1 px-3 h-8 bg-transparent border-b border-slate-300"} placeholder={'Zoeken...'} value={search} onChange={e => setSearch(e.target.value)} />
      {search.length > 0 && <button className={'h-6 w-6 text-red-700 flex items-center justify-center hover:bg-red-200 rounded m-1'} onClick={() => setSearch('')}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>}
    </div>
    <div className={'overflow-y-scroll flex-1'}>
      <button className={props.value === null ? selectedOptionStyle : optionStyle} onClick={() => props.onChange(null)}>Alle ({props.options.length})</button>
      {filteredOptions.map(option => <button key={option.value} className={props.value === option.value ? selectedOptionStyle : optionStyle} onClick={() => props.onChange(option.value)}>{option.label}</button>)}
    </div>
  </div>
}
export const SelectBoxSkeleton: FC<{}> = props => {
  return <div className={"border border-slate-300 bg-slate-300 animate-pulse rounded h-72 flex flex-col items-stretch"}></div>
}
