import React, {FC, useMemo, useState} from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {useApi} from "../api/APIContext";
import {Loading} from "../components/Loading";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {Assessor, Company, Exam, ExamParticipant, Project} from "../api/dto";
import {DataTable} from "../components/data/DataTable";
import {PillSelect} from "../components/form/PillSelect";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDownAZ,
  faArrowUpRightFromSquare,
  faFileExcel,
  faRefresh,
  faTimesCircle,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../util/usePersistentState";
import {Select} from "../components/form/Select";
import {Button} from "../components/form/Button";
import {Input} from "../components/form/Input";
import moment from "moment/moment";
import {useApiCall} from "../api/api";
import {SelectBox, SelectBoxOption, SelectBoxSkeleton} from "../components/form/SelectBox";

const statusFilters = {
  'Alle': (exam: Exam) => exam.archivedAt === null,
  'Afgerond': (exam: Exam) => exam.status === 'Afgerond',
  'In uitvoering': (exam: Exam) => exam.status === 'In uitvoering',
  'Concept': (exam: Exam) => exam.status === 'Concept',
  'Gearchiveerd': (exam: Exam) => exam.status === 'Gearchiveerd',
}
interface FilterState {
  companyId: string|null
  projectId: string|null
  examId: string|null
  contractor: string|null
  subContractor: string|null
  fromDate: Date
  toDate: Date
  status: keyof typeof statusFilters
}

interface SelectedParticipantRow extends ExamParticipant {
  exam: Exam
  project: Project
  company: Company
  assessor: Assessor|null
}
export const BrowseAndFilterPage: FC<{}> = props => {
  const navigate = useNavigate()
  const {exams, projects, companies, participants, assessors, loading} = useApi()
  const [filters, setFilters] = useState<FilterState>({
    companyId: null,
    projectId: null,
    examId: null,
    contractor: null,
    subContractor: null,
    fromDate: moment().subtract(90, 'days').toDate(),
    toDate: moment().toDate(),
    status: 'Afgerond',
  })

  const filteredCandidates: SelectedParticipantRow[] = useMemo(() => {
    const projectsMap = projects.reduce((map, project) => {
      map[project.id] = project
      return map
    }, {} as {[key: string]: Project})
    const companiesMap = companies.reduce((map, company) => {
      map[company.id] = company
      return map
    }, {} as {[key: string]: Company})
    const examsMap = exams.reduce((map, exam) => {
      map[exam.id] = exam
      return map
    }, {} as {[key: string]: Exam})
    const assessorsMap = assessors.reduce((map, assessor) => {
      map[assessor.id] = assessor
      return map
    }, {} as {[key: string]: Assessor})

    const availableProjectIds = projects
      .filter(project => (filters.projectId === null || project.id === filters.projectId))
      .filter(project => (filters.companyId === null || project.companyId === filters.companyId))
      .map(p => p.id)
    const availableExamIds = exams
      .filter(exam => moment(exam.examAt).isBetween(moment(filters.fromDate), moment(filters.toDate), 'day', '[]'))
      .filter(exam => availableProjectIds.includes(exam.projectId))
      .filter(exam => filters.status === 'Alle' || statusFilters[filters.status](exam))
      .map(e => e.id)
    const allParticipants = participants
      .filter(p => filters.examId !== null ? p.examId === filters.examId :availableExamIds.includes(p.examId))
      .filter(p => filters.contractor === null || p.contractor === filters.contractor)
      .filter(p => filters.subContractor === null || p.subContractor === filters.subContractor)

    const sortedParticipants = allParticipants.sort((a, b) => {
      const examA = examsMap[a.examId]
      const examB = examsMap[b.examId]
      const nameComparison = (examA.name??'').localeCompare(examB.name??'');
      if (nameComparison !== 0) return nameComparison;
      return a.position - b.position;
    })

    return sortedParticipants.map(participant => {
      const exam = examsMap[participant.examId]
      const project = projectsMap[exam.projectId]
      const company = companiesMap[project.companyId]
      const assessor = exam.assessorId ? assessorsMap[exam.assessorId] : null
      return {
        ...participant,
        exam,
        project,
        company,
        assessor,
      }
    })
  }, [filters, statusFilters, participants, projects, assessors, exams])
  const setFromDate = (fromDate: Date) => setFilters(old => ({...old, fromDate}))
  const setToDate = (toDate: Date) => setFilters(old => ({...old, toDate}))

  return <ContentContainer size={'lg'}>
    <PageHeader>Bladeren en Filteren</PageHeader>
    {loading ? <>
      <div className={'bg-white border-2 border-slate-100 mb-4 rounded-lg px-4 py-3'}>
        <FilterSettingsSkeleton />
      </div>
    </> : <>
      <div className={'bg-white border-2 border-slate-100 mb-4 rounded-lg px-4 py-3'}>
        <FilterSettings filters={filters} onFilterChange={setFilters} exams={exams} projects={projects} companies={companies} participants={participants}/>
        <div className={"grid grid-cols-3 xl:grid-cols-6 gap-4 mt-6 pt-6 border-t border-slate-200 -mx-4 px-4"}>
          <Select label={'Examen status'} options={Object.fromEntries(Object.keys(statusFilters).map(s => [s,s]))} value={filters.status} onChange={(v) => setFilters(old => ({...old, status: v as keyof typeof statusFilters}))} />
          <Input type={'date'} value={filters.fromDate} onChange={setFromDate} label={'Vanaf (datum)'} />
          <Input type={'date'} value={filters.toDate} onChange={setToDate} label={'Tot en met (datum)'} />
          <div className={`col-span-3 flex flex-col justify-between text-sm font-medium text-brand-900 w-full`}>
            <div>Snelle opties</div>
            <div className={'flex justify-between'}>
              <Button type={'secondary'} size={'md'} text={'deze maand'} onClick={() => {
                setFromDate(moment().startOf('month').toDate())
                setToDate(moment().endOf('month').toDate())
              }} />
              <Button type={'secondary'} size={'md'} text={'vorige maand'} onClick={() => {
                setFromDate(moment().subtract(1, 'month').startOf('month').toDate())
                setToDate(moment().subtract(1, 'month').endOf('month').toDate())
              }} />
              <Button type={'secondary'} size={'md'} text={'deze week'} onClick={() => {
                setFromDate(moment().startOf('week').toDate())
                setToDate(moment().endOf('week').toDate())
              }} />
              <Button type={'secondary'} size={'md'} text={'laatste 90 dagen'} onClick={() => {
                setFromDate(moment().subtract(90, 'days').toDate())
                setToDate(moment().toDate())
              }} />

            </div>
          </div>
        </div>
      </div>
      <ExportCard filteredParticipants={filteredCandidates} filters={filters} />
      <DataTable keyProperty={'id'} data={filteredCandidates} compact columns={[
        {'header': 'Examen', 'property': 'examId', transform: (_, row) => <div className={"flex items-center"}>{row.exam.name ?? '-'} <button className={"text-brand-700 ml-1 px-1 rounded hover:bg-brand-100 cursor-pointer"} onClick={() => navigate(`/exams/${row.examId}`)}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button></div>},
        {'header': 'Dag', 'property': 'examId', transform: (_, row) => (moment(row.exam.examAt).format('YYYY-MM-DD'))},
        {'header': 'Tijd', 'property': 'examId', transform: (_, row) => (moment(row.exam.examAt).format('HH:mm'))},
        {'header': 'Contractor', 'property': 'contractor'},
        {'header': 'Subcontractor', 'property': 'subContractor'},
        {'header': 'Naam', 'property': 'name'},
        {'header': 'Test location', 'property': 'position'},
        {'header': 'Assessor', 'property': 'assessor', transform: (_, row) => `${row.assessor?.first_name ??''} ${row.assessor?.last_name ?? ''}`.trim()},
      ]} placeholder={<div className={"flex flex-col items-center"}>
        <div>Geen resultaten gevonden voor filter instellingen.</div>
        <div><Button type={'secondary'} size={'sm'} text={'Reset filters'} icon={faUndo} onClick={() => {
          setFilters({
            companyId: null,
            projectId: null,
            examId: null,
            contractor: null,
            subContractor: null,
            fromDate: moment().subtract(90, 'days').toDate(),
            toDate: moment().toDate(),
            status: 'Afgerond',
          })
        }} /></div>
      </div>} />
    </>}
  </ContentContainer>
}

const FilterSettings: FC<{filters: FilterState, onFilterChange: (val: FilterState) => void, exams: Exam[], projects: Project[], companies: Company[], participants: ExamParticipant[]}> = props => {

  const companyOptions = useMemo(() => {
    return props.companies.map(company => ({value: company.id, label: company.name}));
  }, [props.companies])

  const projectOptions = useMemo(() => {
    return props.projects
      .filter(project => props.filters.companyId == null || project.companyId === props.filters.companyId)
      .map(project => ({value: project.id, label: project.name}));
  }, [props.projects, props.filters.companyId])

  const examOptions = useMemo(() => {
    const projectOptionsIds: string[] = projectOptions.map((p: SelectBoxOption) => p.value)
    return props.exams
      .filter(exam => moment(exam.examAt).isBetween(moment(props.filters.fromDate), moment(props.filters.toDate), 'day', '[]'))
      .filter(exam => (props.filters.projectId === null && projectOptionsIds.includes(exam.projectId)) || exam.projectId === props.filters.projectId)
      .map(exam => ({value: exam.id, label: exam.name ?? 'Onbekend examen'}));
  }, [props.exams, props.filters.projectId, projectOptions])

  const contractorOptions = useMemo(() => {
    const examOptionsIds: string[] = examOptions.map((e: SelectBoxOption) => e.value)
    const participants = props.participants
      .filter(participant => props.filters.examId !== null ? participant.examId === props.filters.examId : examOptionsIds.includes(participant.examId))
    const contractors = Array.from(new Set(participants.map(participant => participant.contractor)))
    return contractors.map(contractor => ({value: contractor, label: contractor}));
  }, [props.participants, props.filters.examId, props.filters.fromDate, props.filters.toDate, examOptions])

  const subContractorOptions = useMemo(() => {
    const examOptionsIds: string[] = examOptions.map((e: SelectBoxOption) => e.value)
    const participants = props.participants.filter(participant => {
      if (props.filters.contractor !== null) {
        return participant.contractor === props.filters.contractor && examOptionsIds.includes(participant.examId)
      }
      return examOptionsIds.includes(participant.examId);
    })
    const subContractors = Array.from(new Set(participants.map(participant => participant.subContractor).filter(x => !!x)))
    return subContractors.map(subContractor => ({value: subContractor, label: subContractor}));
  }, [props.participants, examOptions, props.filters.contractor])

  return <div className={'grid grid-cols-3 xl:grid-cols-5 gap-4 text-slate-800 text-sm'}>
    <div>
      <strong className={'mb-2 block'}>Bedrijf</strong>
      <SelectBox options={companyOptions} value={props.filters.companyId} onChange={(companyId) => props.onFilterChange({...props.filters, companyId, projectId: null, examId: null, contractor: null, subContractor: null})} />
    </div>
    <div>
      <strong className={'mb-2 block'}>Project</strong>
      <SelectBox options={projectOptions} value={props.filters.projectId} onChange={(projectId) => props.onFilterChange({...props.filters, projectId, examId: null, contractor: null, subContractor: null})} />
    </div>
    <div>
      <strong className={'mb-2 block'}>Examenmoment <span className={"text-slate-400"}>(optioneel)</span></strong>
      <SelectBox options={examOptions} value={props.filters.examId} onChange={(examId) => props.onFilterChange({...props.filters, examId})} />
    </div>
    <div>
      <strong className={'mb-2 block'}>Contractor</strong>
      <SelectBox options={contractorOptions} value={props.filters.contractor} onChange={(contractor) => props.onFilterChange({...props.filters, contractor, subContractor: null})} />
    </div>
    <div>
      <strong className={'mb-2 block'}>Subcontractor</strong>
      <SelectBox options={subContractorOptions} value={props.filters.subContractor} onChange={(subContractor) => props.onFilterChange({...props.filters, subContractor})} />
    </div>
  </div>
}
const FilterSettingsSkeleton: FC<{}> = props => {
  return <div className={'grid grid-cols-3 xl:grid-cols-5 gap-4 text-slate-800 text-sm'}>
    <div>
      <strong className={'mb-2 block'}>Bedrijf</strong>
      <SelectBoxSkeleton />
    </div>
    <div>
      <strong className={'mb-2 block'}>Project</strong>
      <SelectBoxSkeleton />
    </div>
    <div>
      <strong className={'mb-2 block'}>Examenmoment <span className={"text-slate-400"}>(optioneel)</span></strong>
      <SelectBoxSkeleton />
    </div>
    <div>
      <strong className={'mb-2 block'}>Contractor</strong>
      <SelectBoxSkeleton />
    </div>
    <div>
      <strong className={'mb-2 block'}>Subcontractor</strong>
      <SelectBoxSkeleton />
    </div>
  </div>
}



export const ExportCard: FC<{filteredParticipants: SelectedParticipantRow[], filters: FilterState}> = props => {
  const {exportSelectionExcelTestReport} = useApiCall()
  if (props.filteredParticipants.length === 0) {
    return <div className={'bg-amber-100 border-2 border-amber-200 text-amber-900 mb-4 rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Exporteren niet mogelijk</h2>
      Geen resultaten om te exporteren.
    </div>
  }
  const nQuestionSets = new Set(props.filteredParticipants.map(p => p.exam.questionSetId)).size
  if (nQuestionSets > 1) {
    return <div className={'bg-amber-100 border-2 border-amber-200 text-amber-900 mb-4 rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Exporteren niet mogelijk</h2>
      De examens in de selectie gebruiken verschillende vragenlijsten.
    </div>
  }
  const nProjects = new Set(props.filteredParticipants.map(p => p.exam.projectId)).size
  if (nProjects > 1) {
    return <div className={'bg-amber-100 border-2 border-amber-200 text-amber-900 mb-4 rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Exporteren niet mogelijk</h2>
      De examens in de selectie vallen onder verschillende projecten.
    </div>
  }
  const exams = new Set(props.filteredParticipants.map(p => p.examId))
  const nParticipants = props.filteredParticipants.length
  return <div className={'bg-white border-2 border-slate-100 mb-4 rounded-lg px-4 py-3'}>
    <h2 className={"font-bold mb-3"}>Resultaten van {nParticipants} kandidaten exporteren <span className={'text-slate-500'}>(verdeeld over {exams.size} {exams.size === 1 ? 'examen' : 'examens'})</span></h2>
    <Button type={'primary'} size={'md'} text={'Resultaten exporteren (Xlsx)'} icon={faFileExcel} onClick={async () => {
      const blob = await exportSelectionExcelTestReport(Array.from(exams), props.filters.contractor ? [props.filters.contractor] : null, props.filters.subContractor ? [props.filters.subContractor] : null);
      const file = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = file
      const project = props.filteredParticipants[0].project
      const company = props.filteredParticipants[0].company
      a.download = `${company.name} - ${project.name} - Overall Data.xlsx`
      a.click()
      URL.revokeObjectURL(file)
    }} />
  </div>
}
